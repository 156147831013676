<template>
  <div class="container">
    <div class="row">
      <div class="col-12 pm-0 d-flex justify-content-between flex-wrap">
        <div class="d-flex">
          <FormInput type="search" id="search" placeholder="اسم السيارة" input-class="max-w-200px"
                     @changed="searchChanged"/>
          <button class="btn btn-info btn-sm rounded-0" @click="getCars">
            <tooltip title="بحث">
              <i class="fa fa-search"></i>
            </tooltip>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0">
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover" :class="{'shadow-sm': !emptyList}">
            <thead>
            <tr class="bg-bb">
              <th>الاسم الكامل</th>
              <th>رقم الهاتف</th>
              <th>المركبة</th>
              <th>سنة الصنع</th>
              <th>سعر البيع</th>
              <th>تعديل</th>
            </tr>
            </thead>
            <tbody class="bg-light">
            <tr v-if="emptyList">
              <td colspan="8" class="font-weight-bolder">لا يوجد</td>
            </tr>
            <IndexItem
                v-else
                v-for="item in list"
                :key="item.id"
                :selling-car="item"
                @deleted="deleted"
            />
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0">
        <ThePaginator
            :total="total"
            :url="defaultUrl"
            :last-page="lastPage"
            :links="links"
            @link-changed="setLink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ThePaginator from "@/components/UI/ThePaginator.vue";
import FormInput from "@/components/Forms/FormInput.vue";
import Tooltip from "@/components/UI/Tooltip.vue";
import pagination from "@/mixins/pagination";
import IndexItem from "@/components/Pages/Admin/SellCars/IndexItem.vue";

export default {
  components: {IndexItem, Tooltip, FormInput, ThePaginator},
  mixins: [pagination],
  data() {
    return {
      search: '',
    }
  },
  computed: {
    defaultUrl() {
      return 'selling-cars';
    },
    formData() {
      return {
        search: this.search
      }
    }
  },
  methods: {
    searchChanged(event) {
      this.search = !!event ? event.target.value : ''
    },
  },
  async created() {
    await this.setList()
  }
}
</script>

<style scoped>

</style>