<template>
  <tr v-if="!!sellingCar">
    <td>{{ sellingCar.full_name }}</td>
    <td>{{ sellingCar.mobile }}</td>
    <td>{{ sellingCar.car_name }}</td>
    <td>{{ sellingCar.year_of_make }}</td>
    <td>{{ sellingCar.sell_price }}</td>
    <td>
      <button class="btn btn-sm btn-danger rounded-0 mr-1" data-toggle="modal"
              :data-target="`#delete_selling_car_${sellingCar.id}`">
        حذف
      </button>

      <button class="btn btn-sm btn-primary rounded-0" @click="show">
        عرض
      </button>
    </td>
  </tr>
  <teleport to="body">
    <items-destroyer
        title="حذف سؤال"
        :target-id="`delete_selling_car_${sellingCar.id}`"
        :url="`selling-cars/${sellingCar.id}`"
        :row-id="`selling-cars${sellingCar.id}`"
        @deleted="deleted"
    >
      هل أنت متأكد من حذف السؤال ؟
    </items-destroyer>
  </teleport>
</template>

<script>
import ItemsDestroyer from "@/components/Common/ItemsDestroyer.vue";

export default {
  components: {ItemsDestroyer},
  emits: ['deleted'],
  props: {
    sellingCar: {
      required: true,
      type: Object
    }
  },
  computed: {
    imagesId() {
      return !this.sellingCar ? "" : `activation-images-${this.sellingCar.id}`;
    },
    hasImages() {
      return !!this.sellingCar && !!this.sellingCar.images && this.sellingCar.images.length > 0
    }
  },
  methods: {
    show() {
      if (!this.hasImages) {
        http.errorMessage('لا يوجد صور للمركبة')
        return
      }

      const images = [];
      for (const image of this.sellingCar.images) {
        images.push({...image, src: image.url})
      }
      this.$viewerApi({
        images,
      })
    },
    deleted(id) {
      this.$emit('deleted', id);
    },
  }
}
</script>